.template-main-container {
  &.template-id-2 {
    .sf-line {
      border-top-color: transparent;
    }

    &.tm-type-sidebar {

      .right-side-tm-top-part {
        padding: 10px 30px 10px 30px;
        border-radius: 2px;
        transform: translate(-30px, -20px);
        position: relative;
        z-index: 1;
        color: white;

        a {
          color: white;
        }

      }

      .summary-part {
        margin-top: -24px;
      }

      &.sidebar-position-right {
        .right-side-tm-top-part {
          transform: translate(20px, -20px);
        }
      }
    }

    .work-experience-part
    .work-experience-list
    .work-experience-item
    .resp-area
    ul {
      list-style-type: '- ';
    }

    &.tm-type-two-rows {
      .two-r-right {
        .list-section, .work-experience-part {
          padding-left: 40px !important;
        }
      }
    }

    &.theme-id-1 {
      .tm-top-wrapper {
        .right-side-tm-top-part {
          color: #ffffff;

          a {
            color: #ffffff;
          }
        }
      }
    }

  }
}
