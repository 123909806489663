.template-main-container {
  &.template-id-4 {
    --before-after-color: #E0589E;

    .tm-top-wrapper {
      .name-part {
        h3 {
          padding: 4px 12px;
          border-radius: 0;
        }
      }
    }

    .right-side {
      .work-experience-part {
        padding: 0 !important;

        .work-exp-title-wrap {
          font-size: 22px;
          line-height: 32px;
          width: fit-content;
          padding: 2px 16px 2px 48px;
          margin-bottom: 10px;

          h2 {
            margin-bottom: 0;
          }
        }

        .work-experience-list {
          padding: 0 48px 0 48px;
          //margin-top: 10px;

          .work-experience-item {

            .work-exp-item-child {
              &:before {
                content: "";
                width: 1px;
                position: absolute;
                background-color: var(--before-after-color);
                left: -8px;
                top: 2px;
                bottom: 2px;
              }
            }

            &:before, &:after {
              content: "";
              width: 5px;
              height: 5px;
              background-color: var(--before-after-color);
              position: absolute;
            }

            &:before {
              left: -10px;
              top: 2px;
              border-radius: 50px;
            }

            &:after {
              left: -10px;
              bottom: 2px;
              border-radius: 0;
              transform: rotate(45deg);
            }
          }
        }
      }

      .list-section {
        padding: 0 !important;

        .list-section-title-wrapper {
          font-size: 22px;
          line-height: 32px;
          width: fit-content;
          padding: 2px 16px 2px 48px;
          margin-bottom: 10px;

          h2 {
            margin-bottom: 0;
          }
        }

        .ul-wrapper {
          padding: 0 48px 0 48px;
          //margin-top: 10px;

          .list-sec-ul-parent {
            position: relative;

            &:before, &:after {
              content: "";
              width: 5px;
              height: 5px;
              background-color: var(--before-after-color);
              position: absolute;
            }

            &:before {
              left: -9px;
              top: 2px;
              border-radius: 50px;
            }

            &:after {
              left: -9px;
              bottom: 2px;
              border-radius: 0;
              transform: rotate(45deg);
            }

            ul {
              &:before {
                content: "";
                width: 1px;
                position: absolute;
                background-color: var(--before-after-color);
                left: -8px;
                top: 2px;
                bottom: 2px;
              }
            }
          }
        }
      }
    }

    &.tm-type-sidebar {

      &.sidebar-position-right {
        .tm-st-banner {
          .tm-st-banner-settings {
            &.img-settings {
              left: 180px;
              right: initial;
            }
          }
        }
      }

      .tm-top-wrapper {
        .right-side-tm-top-part {
          .top-part {
            justify-content: flex-start;

            .img-part-wrapper {
              padding: 0;
              margin-right: 20px;
              background-color: white;
            }
          }
        }
      }

      .summary-part {
        margin-top: 10px;
        margin-bottom: -8px;
      }

      .right-side {
        .work-experience-part {
          .work-exp-title-wrap {
            padding-left: 30px;
          }

          .work-experience-list {
            padding-left: 30px;
          }
        }
      }

      &.sidebar-position-right {
        .right-side {
          .work-experience-part {
            padding: 0;

            .work-exp-title-wrap {
              padding-left: 48px;
              padding-right: 30px;
            }

            .work-experience-list {
              padding-left: 48px;
              padding-right: 30px;
            }
          }
        }
      }

    }

    &.tm-type-one-row {

    }

    &.tm-type-two-rows {
      .template-page-parent {
        .template-page {
          .two-row-tm {
            .two-row-body {
              .two-r-left {
                .work-experience-part {
                  .work-experience-list {
                    padding-right: 0;
                  }
                }

                .list-section {
                  .ul-wrapper {
                    padding-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .sf-line {
      border-top-color: transparent;
    }

    .left-side {
      .sf-line {
        display: none;
      }
    }
  }
}
