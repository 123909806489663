.template-main-container {
  &.template-id-3 {

    .template-page {
      .right-side {
        .work-experience-part {
          padding: 0;

          .work-exp-title-wrap {
            font-size: 22px;
            line-height: 32px;
            width: fit-content;
            padding: 2px 16px 2px 48px;
            margin-bottom: 4px;
            border-radius: 0 8px 8px 0;

            h2 {
              margin-bottom: 0;
            }
          }

          .work-experience-list {
            padding: 0 48px 0 48px;
          }
        }

        .list-section {
          padding: 0;

          .list-section-title-wrapper {
            font-size: 22px;
            line-height: 32px;
            width: fit-content;
            padding: 2px 16px 2px 48px;
            margin-bottom: 4px;
            border-radius: 0 8px 8px 0;

            h2 {
              margin-bottom: 0;
            }
          }

          .ul-wrapper {
            padding: 0 48px 0 48px;
          }
        }

      }

      .left-side {
        .sf-line {
          display: none;
        }
      }
    }

    &.tm-type-sidebar {
      .template-page-parent {
        .template-page {
          .img-part-wrapper {
            padding: 50px 0 30px 0;
            background-color: white;
          }

          .summary-part {
            margin-top: 10px;
            margin-bottom: -8px;
          }

          .right-side {
            .work-experience-part {
              .work-exp-title-wrap {
                padding-left: 30px;
              }

              .work-experience-list {
                padding-left: 30px;
              }
            }

            .list-section {
              .list-section-title-wrapper {
                padding-left: 30px;
              }

              .ul-wrapper {
                padding-left: 30px;
              }
            }
          }

          .left-side {
            .social-info-sidebar {
              padding-bottom: 40px;

              &:after {
                content: "";
                background-color: white;
                position: absolute;
                width: 100%;
                height: 20px;
                left: 0;
                bottom: 0;
              }
            }
          }
        }
      }

      &.sidebar-position-right {
        .template-page {
          .right-side {
            .work-experience-part {
              padding: 0;

              .work-exp-title-wrap {
                padding-left: 48px;
                padding-right: 30px;
              }

              .work-experience-list {
                padding-left: 48px;
                padding-right: 30px;
              }
            }

            .list-section {
              .list-section-title-wrapper {
                padding-left: 48px;
                padding-right: 30px;
              }

              .ul-wrapper {
                padding-left: 48px;
                padding-right: 30px;
              }
            }
          }
        }
      }
    }

    &.tm-type-two-rows {
      .template-page {
        .two-r-left {
          .work-experience-part {

            .work-experience-list {
              padding-right: 0;
            }
          }

          .list-section {
            .ul-wrapper {
              padding-right: 0;
            }
          }
        }
      }
    }

    .sf-line {
      border-top-color: transparent;
    }

  }
}
