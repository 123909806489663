@use "../../global/variables" as variables;
@use "../../global/placeholders" as placeholders;

.template-wrapper {
  .template-container {

  }
}

.tm-top-part-container {
  height: 320px;
  overflow: auto;

  > li {
    border-bottom: 1px solid lightgray;

    &:last-child {
      border-bottom: none;
    }
  }
}

.template-main-container {
  width: 952px;
  --tm-link-color: #4b57dc;
  --tm-sidebar-link-color: #4b57dc;
  --sidebarPdLeft: 26px;
  --sidebarPdRight: 20px;
  --sidebarPdTop: 50px;
  --sidebarPdBottom: 50px;

  --rightSidePaddingLeft: 30px;
  --rightSidePaddingRight: 48px;
  margin: 0 0 200px 0;

  --before-after-color: #4B57DC;

  .editor-link-el {
    cursor: pointer;
  }

  &.tm-preview-mode {
    overflow: hidden;
    pointer-events: none;
  }

  &.sidebar-position-right {
    .template-page-parent {
      .template-page {
        flex-direction: row-reverse;
      }
    }
  }

  .template-page-parent {
    position: relative;
    width: 952px;
    height: 1346px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .template-page {
      display: flex;
      height: 100%;
    }
  }

  @extend %tmOverlay;
  @extend %tmContenteditable;

  @extend %tmSettingsBanner;

  @extend %imgPartWrapper;
  @extend %tmH1;
  @extend %tmH2;
  @extend %tmLink;
  @extend %tmSfLine;

  @extend %tmSummaryPart;
  @extend %tmSocialInfo;

  @extend %tmTopWrapper;
  @extend %tmTopPart;
  @extend %leftSideTemplate;
  @extend %rightSideTemplate;
  @extend %oneRowSIdeTemplate;
  @extend %twoRowSIdeTemplate;

  @extend %workExpSection;
  @extend %listSections;
  @extend %tmReferencesSection;

  @extend %tmEmptySection;
  @extend %tmPagination;
  @extend %tmPageSpace;

  &.tm-type-sidebar {
    .template-page-parent {
      .template-page {
        .left-side {

        }
      }
    }
  }

}

%tmOverlay {
  .template-overlay {
    position: absolute;
    background: rgba(52, 64, 84, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 4px;
  }
}

%tmContenteditable {
  [contenteditable] {
    outline: 0 solid transparent;
    cursor: text;
    min-height: 10px;
    word-break: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  [contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    width: auto;
    opacity: .5;
  }

  [contenteditable][placeholder]:empty:focus:before {
    content: attr(placeholder);
    width: auto;
    opacity: .2;
  }
}

/* ================================================================================================================== */

%tmSettingsBanner {
  .tm-st-banner {
    height: 50px;
    position: absolute;
    z-index: 10;
    top: -60px;
    left: 0;
    width: fit-content;
    padding: 6px;

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;

    > i {
      font-size: 22px;
      padding: 6px;
      cursor: pointer;
      transition: .3s;
      border-radius: 4px;
      color: #303030;

      &:last-child {
        //padding-right: 0;
      }

      &:hover, &.active {
        //color: #4b57dc;
        background-color: #ebebeb;
      }

    }

    .new-entry {
      position: relative;
      background: #4B57DC;
      padding: 6px 10px;
      border-radius: 8px;
      color: white;
      margin-right: 6px;
      cursor: pointer;
      white-space: nowrap;
      @extend %flexCenterCenter;
      font-size: 14px;
      min-width: 118px;
      height: 34px;

      > i {
        padding: 0;
        margin-right: 6px;
        color: white;
        font-size: 22px;
      }
    }

    .tm-st-banner-settings {
      //z-index: 3;

      &.img-settings {
        padding: 16px;
        position: absolute;
        background-color: white;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        top: 56px;
        left: 180px;
        display: flex;

        .img-shape-icon-wr {
          margin-right: 16px;
          position: relative;

          &:last-child {
            margin-right: 0;
          }

          .ph-star {
            position: absolute;
            z-index: 1;
            top: -12px;
            right: -6px;
            color: #4b57dc;
          }

          .img-shape {
            border: 2px solid #303030;
            width: 38px;
            height: 38px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #303030;
            cursor: pointer;
            border-radius: 4px;

            &:hover, &.active {
              background-color: initial;
              border-color: #4b57dc;
              color: #4b57dc;
            }

            &.round {
              border-radius: 100px;
            }

            &.left-top {
              border-radius: 20px 50px 50px 50px;
            }

            &.right-top {
              border-radius: 50px 20px 50px 50px;
            }

            &.left-bottom {
              border-radius: 50px 50px 50px 20px;
            }

            &.right-bottom {
              border-radius: 50px 50px 20px 50px;
            }
          }
        }
      }

      &.work-exp {
        position: absolute;
        top: 52px;
        right: 0;
        min-width: 200px;
        border: 1px solid lightgray;
        border-radius: 8px;
        background: #FFF;
        padding: 16px 16px 16px 14px;

        .checkboxes-container {
          .sf-checkbox {
            margin-bottom: 6px;
            text-transform: capitalize;
            border-radius: 4px;
            border: 1px solid transparent;
            padding: 2px 4px;

            label {
              white-space: nowrap;
            }

            &:hover {
              border: 1px solid rgba(75, 87, 220, 0.2);
              background: rgba(194, 197, 203, 0.2);
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

      }
    }

  }

  &.tm-type-sidebar {
    &.sidebar-position-right {
      .tm-st-banner {
        .tm-st-banner-settings {
          &.img-settings {
            left: initial;
            right: 180px;
          }
        }
      }
    }
  }
}

/* ================================================================================================================== */

%tmH1 {
  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    padding: 0 2px;
    position: relative;
    z-index: 1;
    @extend %sectionHover;
  }
}

%tmH2 {
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    //margin-bottom: 4px;
    border-radius: 4px;
    padding: 0 2px;
    position: relative;
    z-index: 1;
    @extend %sectionHover;
  }
}

%tmLink {
  a {
    color: var(--tm-link-color);
    text-decoration: underline;
    cursor: initial;

    &:hover {
      text-decoration: initial;
    }
  }

  &.tm-type-sidebar {
    .left-side {
      a {
        color: var(--tm-sidebar-link-color);
      }
    }
  }
}

%tmSfLine {
  .sf-line {
    border-top: 1px solid #E3E3E3;
    //border-radius: 8px;
    margin: 16px auto;
    width: calc(100% - (50px + 50px));
  }

  &.tm-type-sidebar {
    .left-side {
      .sf-line {
        margin: 4px 0 4px 40px;
        width: calc(100% - 60px);
      }
    }

    .right-side {
      .sf-line {
        margin: 16px 0 16px 30px;
        width: calc(100% - (50px + 32px));
      }
    }

    &.sidebar-position-right {
      .left-side {
        .sf-line {
          margin: 4px 0 4px 20px;
        }
      }

      .right-side {
        .sf-line {
          margin: 16px 0 16px 48px;
        }
      }
    }
  }

  &.tm-type-one-row {
    .sf-line {
      margin: 16px auto;
      width: calc(100% - (50px + 50px));
    }
  }

  &.tm-type-two-rows {
    .two-r-left {
      .sf-line {
        margin-left: 50px;
        width: calc(100% - 50px);
      }
    }

    .two-r-right {
      .sf-line {
        margin: 16px 0;
        width: calc(100% - 50px);
      }
    }
  }
}

/* ================================================================================================================== */

%tmSummaryPart {
  &.tm-type-sidebar {
    .summary-part {
      margin: 0 48px 0 30px;

      &.has-icon {
        margin: 0 48px 0 66px;
      }
    }

    &.sidebar-position-right {
      .summary-part {
        margin: 0 30px 0 48px;

        &.has-icon {
          margin-left: 66px;
        }
      }
    }
  }

  &.tm-type-one-row {
    .summary-part {
      margin: 0 50px;

      &.has-icon {
        margin-left: 66px;
      }
    }
  }

  &.tm-type-two-rows {
    .summary-part {
      margin: 0 48px 0 48px;

      &.has-icon {
        margin-left: 66px;
      }
    }
  }

  .summary-part {
    position: relative;
    z-index: 1;

    .summary-title-wrap {
      @extend %tmTitleIcon;

      > h2 {
        margin-bottom: 2px;

        &:hover {
          background-color: initial;
          border-color: transparent;
        }
      }
    }

    > p {
      padding: 2px 4px;
    }

    border-radius: 4px;
    @extend %sectionHover;
  }
}

%tmSocialInfo {
  .tm-social-infos {
    padding: 2px;
    cursor: pointer;
    border: 1px solid transparent;
    margin-top: 16px;
    transition: .3s;
    border-radius: 4px;

    > ul {
      display: flex;
      flex-wrap: wrap;

      li {
        //min-width: 200px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 4px;
        margin-top: 4px;
        word-break: break-all;


        > i {
          margin-right: 4px;

          &.ri-skype-line {
            margin-bottom: 2px;
          }
        }

        &:last-child {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
    }

    &:hover {
      background-color: rgba(194, 197, 203, 0.2);
      border: 1px solid rgba(75, 87, 220, 0.2);
    }
  }
}

/* ================================================================================================================== */

%imgPartWrapper {


  &.tm-type-sidebar {
    .img-part-wrapper {

      @extend %flexCenterCenter;
      padding: 50px 0 20px 0;

      .img-part {
        margin: 0;
      }
    }
  }
}

%tmTopWrapper {
  &.tm-type-one-row, &.tm-type-two-rows {
    .tm-top-wrapper {
      .social-name-img-wr {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: var(--sidebarPdTop) 0 20px 0;

        h3 {
          padding: 0 2px;
          z-index: 1;
          position: relative;
          @extend %sectionHover;
        }

        .top-left-side {
          display: flex;
          padding-left: 50px;

          .name-sr-name-part {
            margin-right: 24px;
            min-width: 200px;

            h3 {
              padding: 0 2px;
              position: relative;
              z-index: 1;
              @extend %sectionHover;
            }
          }
        }
      }

      .social-info {
        border-radius: 8px 0 0 8px;
        flex-grow: 2;
        padding: 12px 20px 12px 50px;
        height: fit-content;
        min-width: 366px;
        cursor: pointer;
        transition: .3s;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        font-size: 13px;
        margin-top: 16px;
        width: 100%;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          li {
            word-break: break-all;
            word-wrap: break-word;

            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 4px;
            margin-top: 4px;
            pointer-events: none;
            display: flex;
            align-items: flex-start;
            margin-right: 20px;

            &:last-child {
              margin-right: 0;
            }

            > a {
              color: inherit;
            }

            > i {
              margin-right: 4px;
              line-height: inherit;
            }

          }
        }

        &:hover {
          opacity: .8;
        }
      }

      &.tm-top-wrapper-type-2 {
        .social-name-img-wr {
          flex-direction: row;
          justify-content: space-between;

          .social-info {
            margin-top: 0;
            padding-left: 20px;
            max-width: 460px;
          }
        }
      }

      &.tm-top-wrapper-type-3 {
        padding-bottom: 16px;
        margin-bottom: 24px;
        border-radius: 4px 4px 0 0;

        .social-name-img-wr {
          flex-direction: row;
          justify-content: space-between;
          padding: 50px 48px 0 48px;

          .top-left-side {
            flex-direction: column;
            padding-left: 0;

            .social-info {
              margin-top: 8px;
              padding-left: 0;
            }
          }

          .img-part-wrapper {
            .img-part {
              margin-right: 0;
            }

            .tm-st-banner {
              left: initial;
              right: 0;

              .tm-st-banner-settings {
                left: initial;
                right: 174px;
              }
            }
          }

        }

        .sf-line {
          margin-bottom: 16px;
          margin-top: 16px;
          width: calc(100% - 98px);
        }

        sf-tm-summary {
          .sf-line {
            display: none;
          }
        }
      }

      &.tm-top-wrapper-type-4 {
        .name-part {
          padding-right: 48px;
        }

        .summary-part {
          margin-left: 0;
          margin-top: 10px;
          margin-bottom: 4px;
        }
      }

      &.tm-top-wrapper-type-5 {
        .top-left-side {
          flex-direction: row-reverse;

          .img-part-wrapper {
            .tm-st-banner-settings {
              &.img-settings {
                left: initial;
                right: 0;
              }
            }

            .img-part {
              margin-right: 50px;
            }
          }

          .name-part {
            padding-right: 24px;
          }

          .summary-part {
            margin: 10px 24px 4px 0;
          }
        }
      }

      &.tm-top-wrapper-type-6 {
        .name-part {
          padding-right: 48px;
        }

        .summary-part {
          margin-left: 0;
          margin-top: 10px;
          margin-bottom: 4px;
        }

        .social-info {
          position: relative;
          background-color: transparent !important;
          color: rgb(48, 48, 48);
          border-top: 1px solid var(--before-after-color);
          border-bottom: 1px solid var(--before-after-color);
          margin-right: 50px;
          margin-left: 50px;
          width: calc(100% - 100px);
          border-radius: 0;
          padding: 8px 0 8px 0;

          &:before, &:after, ul:before, ul:after {
            content: "";
            width: 6px;
            height: 6px;
            background-color: var(--before-after-color);
            position: absolute;
            border-radius: 50px;
          }

          &:before {
            left: -3px;
            top: -3px;
          }

          &:after {
            left: -3px;
            bottom: -3px;
          }

          ul {
            &:before {
              right: -3px;
              top: -3px;
            }

            &:after {
              right: -3px;
              bottom: -3px;
            }
          }

        }

      }

      &.tm-top-wrapper-type-7 {
        .social-name-img-wr {
          flex-direction: row;
          justify-content: space-between;

          .social-info {
            margin-top: 0;
            display: flex;
            justify-content: flex-end;
            background-color: transparent;
            padding: 0 50px 0 20px;
            max-width: 370px;

            ul {
              flex-wrap: initial;
              flex-direction: column;
              align-items: flex-start;

              li {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  &.tm-type-sidebar {
    .tm-top-wrapper {
      .social-info {
        padding: 2px;
        cursor: pointer;
        border: 1px solid transparent;
        transition: .3s;
        border-radius: 4px;

        > ul {
          li {
            margin-bottom: 8px;
            word-break: break-all;
            word-wrap: break-word;
            display: flex;

            > i {
              margin-top: 2px;
              margin-right: 4px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &:hover {
          background-color: rgba(194, 197, 203, 0.2);
          border: 1px solid rgba(75, 87, 220, 0.2);
        }
      }
    }
  }

  &.template-id-7 {
    &.tm-type-sidebar {
      .tm-top-wrapper {
        .social-name-img-wr {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: var(--sidebarPdTop) 0 20px 0;

          h3 {
            padding: 0 2px;
            z-index: 1;
            position: relative;
            @extend %sectionHover;
          }

          .top-left-side {
            display: flex;
            padding-left: 50px;

            .name-sr-name-part {
              margin-right: 24px;
              min-width: 200px;

              h3 {
                padding: 0 2px;
                position: relative;
                z-index: 1;
                @extend %sectionHover;
              }
            }
          }
        }

        .social-info {
          border-radius: 8px 0 0 8px;
          flex-grow: 2;
          padding: 12px 20px 12px 50px;
          height: fit-content;
          min-width: 366px;
          cursor: pointer;
          transition: .3s;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          font-size: 13px;
          margin-top: 16px;
          width: 100%;

          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            li {
              word-break: break-all;
              word-wrap: break-word;

              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              margin-bottom: 4px;
              margin-top: 4px;
              pointer-events: none;
              display: flex;
              align-items: flex-start;
              margin-right: 20px;

              &:last-child {
                margin-right: 0;
              }

              > a {
                color: inherit;
              }

              > i {
                margin-right: 4px;
                line-height: inherit;
              }

            }
          }

          &:hover {
            opacity: .8;
          }
        }

        &.tm-top-wrapper-type-6 {
          h3 {
            font-style: normal;
            font-weight: 700;
          }

          .img-part-wrapper {
            padding: 0;
            position: relative;
          }

          .name-part {
            padding-right: 48px;
          }

          .summary-part {
            margin-left: 0;
            margin-top: 10px;
            margin-bottom: 4px;
          }

          .social-info {
            position: relative;
            background-color: transparent !important;
            color: rgb(48, 48, 48);
            border-top: 1px solid var(--before-after-color);
            border-bottom: 1px solid var(--before-after-color);
            margin-right: 50px;
            margin-left: 50px;
            width: calc(100% - 100px);
            border-radius: 0;
            padding: 8px 0 8px 0;

            &:before, &:after, ul:before, ul:after {
              content: "";
              width: 6px;
              height: 6px;
              background-color: var(--before-after-color);
              position: absolute;
              border-radius: 50px;
            }

            &:before {
              left: -3px;
              top: -3px;
            }

            &:after {
              left: -3px;
              bottom: -3px;
            }

            ul {
              &:before {
                right: -3px;
                top: -3px;
              }

              &:after {
                right: -3px;
                bottom: -3px;
              }
            }

          }

        }

      }
    }
  }

}

%tmTopPart {
  .right-side-tm-top-part {
    padding: 2px 48px 2px 30px;
  }

  &.sidebar-position-right {
    .right-side-tm-top-part {
      padding: 2px 30px 2px 48px;
    }
  }
}

%leftSideTemplate {
  .left-side {
    width: 306px;
    border-radius: 4px 0 0 4px;
    font-family: variables.$sfFontFamily;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: normal;
    flex-shrink: 0;

    .sidebar-reverse-wr {
      font-size: 28px;
      position: absolute;
      top: -45px;
      left: 0;
      box-shadow: 0 3px 16px rgba(40, 25, 45, 0.08);

      > i {
        @extend %icon-tag;
        font-size: 28px;
      }

      @media only screen and (max-width: 1150px) {
        top: -50px;
        left: 128px;
      }
    }

    h2 {
      color: #FFFFFF;
      @extend %darkSectionHover;
    }

    h3 {
      color: #FFFFFF;
      font-size: 14px;
    }

    .left-side-content {
      padding-bottom: var(--sidebarPdBottom);
      //background-color: antiquewhite;

      .edit-mode {
        background-color: white !important;
        color: #303030;
        position: relative;
        z-index: 4 !important;
        border-radius: 4px;

        > h2, h3 {
          color: inherit;
        }

      }

    }

  }

  &.sidebar-position-right {
    .left-side {
      //padding-left: 20px;
      //padding-right: 26px;
      border-radius: 0 4px 4px 0;
    }
  }
}

%rightSideTemplate {
  .right-side {
    padding: var(--sidebarPdTop) 0 0 0;
    color: #303030;
    font-size: 14px;
    width: 100%;

    .right-side-body {
      padding-bottom: 50px;
      //background-color: aliceblue;
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    .top-part {
      @extend %flexBetween;

      .name-part {
        h1 {
          min-width: 300px;
          border-radius: 4px;
        }

        h3 {
          border-radius: 4px;
          padding: 0 2px;
          z-index: 1;
          position: relative;
          @extend %sectionHover;
        }
      }
    }

    .tags-content-section {
      .tags-list {
        display: flex;
        flex-wrap: wrap;

        > div {
          //background-color: #4B57DC;
          border-radius: 4px;
          color: #FFFFFF;
          padding: 2px 10px;
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }
    }

    .edit-mode {
      background-color: white !important;
      color: #303030;
      position: relative;
      z-index: 4 !important;
      border-radius: 4px;

      > h2 {
        color: inherit;
      }
    }

  }
}

%oneRowSIdeTemplate {
  .one-row-tm {
    color: #303030;
    font-size: 14px;
    width: 100%;
    padding: 0;

    .one-row-body {
      padding: 0 0 50px 0;
      //background-color: aliceblue;
    }
  }
}

%twoRowSIdeTemplate {
  .two-row-tm {
    color: #303030;
    font-size: 14px;
    width: 100%;
    padding: 0;

    .two-row-body {
      display: flex;

      .two-r-left {
        height: fit-content;
        //background-color: aliceblue;
        padding-bottom: 50px;

        margin-right: 16px;
        flex-grow: 1;
        min-width: 300px;
      }

      .two-r-right {
        margin-left: 16px;
        flex-grow: 1;

        height: fit-content;
        //background-color: aliceblue;
        padding-bottom: 50px;
        min-width: 300px;
      }

    }
  }
}

/* ================================================================================================================== */

%workExpSection {
  .work-experience-part {
    padding: 0 48px 0 48px;
    position: relative;
    //background-color: lightgray;

    .work-exp-title-wrap {
      position: relative;
      @extend %tmTitleIcon;
    }

    &.has-icon {
      padding-left: 66px !important;
    }

    .work-experience-list {
      .work-experience-item {
        border-radius: 4px;
        position: relative;
        z-index: 1;
        padding: 2px 4px;
        margin-bottom: 12px;
        //padding-bottom: 16px;

        .exp-title {
          .title-wr {
            h3 {
              flex-grow: 1;
              margin-bottom: 4px;
            }

            @extend %flexBetween;

            .tm-date-container {
              position: relative;

              .rs {
                cursor: pointer;
                white-space: nowrap;
              }

              /*.tm-date-wrapper {
                position: absolute;
                z-index: 2;
                top: 28px;
                right: 0;
              }*/
            }

          }

          .sb-title-wr {
            @extend %flexBetween;

            h4 {
              flex-grow: 1;
              margin-bottom: 8px;
              margin-top: -4px
            }

            .tm-sec-address {
              margin-bottom: 4px;
            }
          }

          .rs {
            white-space: nowrap;
          }

          .tm-date-wrapper {
            position: absolute;
            z-index: 2;
            top: 28px;
            right: 0;
          }

        }

        .exp-desc {
          margin: 0 0 8px 0;
        }

        .resp-area {

          .achievements {
            font-weight: 600;
            margin-bottom: 6px;
          }

          ul {
            margin: 0;
            list-style: initial;
            padding-left: 40px;
            display: grid;

            &.cols-2 {
              gap: 16px 26px;
            }

            &.cols-3 {
              gap: 16px 26px;
            }
          }
        }

        @extend %sectionHover;
      }
    }
  }

  &.tm-type-sidebar {
    .left-side {
      .work-experience-part {
        //background-color: cadetblue !important;
        padding: 16px 20px 16px 40px;
        border-radius: 4px;

        .work-exp-title-wrap {
          > i {
            left: -40px !important;
          }
        }

        .work-experience-list {
          .work-experience-item {

            .exp-title {
              .title-wr {
                display: flex;
                flex-direction: column;
              }

              .sb-title-wr {
                display: flex;
                flex-direction: column;
              }
            }

            .exp-desc {
              padding-bottom: 8px;
            }

            .resp-area {

              ul {
                padding-left: 30px;
              }
            }

            @extend %darkSectionHover;
          }
        }
      }
    }

    .right-side {
      .work-experience-part {
        padding: 0 48px 0 30px;
      }
    }

    &.sidebar-position-right {
      .left-side {
        .work-experience-part {
          padding: 16px 40px 16px 20px;
        }
      }

      .right-side {
        .work-experience-part {
          padding: 0 30px 0 48px;
        }
      }
    }

  }

  &.tm-type-one-row {

  }

  &.tm-type-two-rows {
    .two-row-tm {
      .two-r-left {
        .work-experience-part {
          padding: 0 0 0 48px;
        }
      }

      .two-r-right {
        .work-experience-part {
          padding: 0 48px 0 0;
        }
      }

      .work-experience-part {
        .work-experience-list {
          .work-experience-item {
            .rs {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

%listSections {
  .right-side {
    .list-section {
      //background-color: lightblue;
      position: relative;

      .ul-wrapper {
        position: relative;

        .list-sec-ul {
          margin: 0;
          padding: 4px 4px 2px 4px;
          list-style-type: none;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          position: relative;
          z-index: 1;

          > li {
            display: flex;
            align-items: center;
            margin-right: 20px;
            line-height: 24px;
            margin-bottom: 4px;

            .list-text {
              &:focus {
                text-decoration: underline;
              }
            }

            > i {
              margin-right: 4px;
              cursor: pointer;
            }
          }

          &.skill-with-text {
            > li {
              flex-direction: column;
              align-items: flex-start;

              .lng-skill-text {
                font-size: 12px;
              }
            }
          }

          @extend %sectionHover;
        }
      }

      &.has-infographic {
        > h2 {
          margin-bottom: 2px;
        }

        .ul-wrapper {
          .list-sec-ul {
            padding-top: 10px;

            > li {
              @extend %flexCenterBetween;
              min-width: 180px;
              margin-right: 30px;
              margin-bottom: 8px;

              .list-text {
                margin-right: 8px;
                min-width: 46px;
              }

              @extend %roundInp;
            }
          }
        }

        &.has-chip {
          &.bg-color-full {
            .ul-wrapper {
              .list-sec-ul {
                > li {
                  .lng-rounds {
                    > div {
                      border: 1px solid white;

                      &.active {
                        background-color: white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.has-chip {
        > h2 {
          margin-bottom: 2px;
        }

        .ul-wrapper {
          .list-sec-ul {
            padding-top: 0;
            padding-bottom: 0;

            > li {
              background-color: #FFFFFF;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 6px;
              color: #303030;
              margin-top: 4px;
              margin-bottom: 4px;
              margin-right: 8px;

              .list-text {
                padding: 2px 8px;

                &:focus {
                  text-decoration: initial;
                }
              }
            }

            &.skill-with-text {
              > li {
                .list-text {
                  padding: 0 8px;
                }

                .lng-skill-text {
                  padding: 0 8px;
                  margin-right: 0;
                }
              }
            }
          }
        }

        &.has-icon {
          .ul-wrapper {
            .list-sec-ul {
              > li {
                > i {
                  margin-left: 6px;
                }

                .list-text {
                  padding-left: 2px;
                }
              }
            }
          }
        }

        &.has-infographic {
          .ul-wrapper {
            .list-sec-ul {
              > li {
                padding-right: 6px;
              }
            }
          }
        }
      }

      .list-section-title-wrapper {
        position: relative;
        @extend %tmTitleIcon;

        > i {
          left: -40px !important;
        }
      }

      &.has-title-icon {
        padding-left: 66px !important;
      }

    }
  }

  .left-side {
    .list-section {
      border-radius: 4px;
      position: relative;
      z-index: 2;
      //background-color: brown !important;

      .ul-wrapper {
        position: relative;

        .list-sec-ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          border-radius: 4px;

          &.wrap-list {
            display: flex;
            flex-wrap: wrap;

            li {
              margin-right: 4px;
            }
          }

          &.skill-with-text {
            &.wrap-list {
              flex-wrap: initial;
              flex-direction: column;

              li {
                flex-direction: column;
                justify-content: flex-start;

                .lng-skill-text {
                  width: 100%;
                }

                li {
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }

            &.grid-list {
              display: grid;
              gap: 2px 6px;
              grid-template-columns: repeat(2, 1fr);

              li {
                flex-direction: column;

                .lng-skill-text {
                  width: 100%;
                }
              }
            }
          }

          > li {
            padding: 2px 4px;
            margin-bottom: 4px;
            line-height: 24px;
            display: flex;
            align-items: center;

            > i {
              margin-right: 4px;
              margin-left: 2px;
              cursor: pointer;
            }

            .list-text {
              width: 100%;

              &:focus {
                text-decoration: underline;
              }
            }

            .lng-skill-text {
              margin: 0;
              font-size: 13px;
              cursor: initial;
            }

          }

          @extend %darkSectionHover;
        }
      }

      &.has-infographic {

        .ul-wrapper {
          .list-sec-ul {
            position: relative;
            z-index: 1;

            > li {
              @extend %roundInp;
            }
          }
        }

      }

      &.lng {
        ul {
          li {
            @extend %flexCenterBetween;

            .lng-rounds {
              @extend %flexCenter;
              margin-left: 10px;

              > div {
                width: 16px;
                height: 16px;
                border-radius: 50px;
                cursor: pointer;
                margin-right: 10px;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }

      &.has-chip {
        ul {
          li {
            background-color: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            color: #303030;
            margin-bottom: 8px;
            padding-right: 6px;
          }
        }
      }

      .list-section-title-wrapper {
        position: relative;
        @extend %tmTitleIcon;

        > i {
          left: -40px !important;
        }
      }

      &.has-title-icon {
        padding-left: 66px !important;
      }
    }
  }

  &.tm-type-sidebar {
    .left-side {
      .list-section {
        padding: 16px 20px 16px 40px;
      }
    }

    .right-side {
      .list-section {
        padding: 0 48px 0 30px;
      }
    }

    &.sidebar-position-right {
      .left-side {
        .list-section {
          padding: 16px 40px 16px 20px;
        }
      }

      .right-side {
        .list-section {
          padding: 16px 20px 16px 48px;
        }
      }
    }
  }

  &.tm-type-one-row {
    .list-section {
      padding: 0 48px 0 48px;
    }
  }

  &.tm-type-two-rows {
    .two-r-left {
      .list-section {
        padding: 0 0 0 48px;
      }
    }

    .two-r-right {
      .list-section {
        padding: 0 48px 0 0;
      }
    }
  }
}

%tmReferencesSection {
  .left-side {
    .references {
      padding: 16px 0 16px 20px;
      border-radius: 4px;

      .references-title-wrap {
        position: relative;
        @extend %tmTitleIcon;
      }

      &.has-icon {
        padding-left: 66px !important;
      }

      .references-list-container {
        .reference-item {
          font-weight: 500;
          margin-bottom: 16px;
          position: relative;
          z-index: 1;

          .ref-text {
            margin-bottom: 4px;
          }

          .ref-color-text {
            margin-bottom: 4px;
          }

          .contact-part {
            .tel, .email {
              margin-bottom: 4px;
              white-space: nowrap;
            }
          }

          @extend %darkSectionHover;
        }
      }
    }
  }

  .right-side {
    .references {
      .references-title-wrap {
        position: relative;
        @extend %tmTitleIcon;
      }

      &.has-icon {
        padding-left: 66px !important;
      }

      .references-list-container {
        display: flex;
        flex-wrap: wrap;

        .reference-item {
          font-weight: 500;
          margin-bottom: 16px;
          position: relative;
          z-index: 1;
          margin-right: 20px;
          padding: 0 2px;

          .ref-text {
            margin-bottom: 4px;
          }

          .ref-color-text {
            margin-bottom: 4px;
          }

          .contact-part {
            .tel, .email {
              margin-bottom: 4px;
              white-space: nowrap;
            }
          }

          @extend %sectionHover;
        }
      }
    }
  }

  &.tm-type-sidebar {
    .left-side {
      .references {
      }
    }

    .right-side {
      .references {
        padding: 0 48px 0 30px;
      }
    }
  }

  &.tm-type-one-row {
    .references {
      padding: 0 48px 0 48px;
    }
  }

  &.tm-type-two-rows {
    .two-r-left {
      .references {
        padding: 0 0 0 48px;
      }
    }

    .two-r-right {
      .references {
        padding: 0 48px 0 0;
      }
    }
  }
}

/* ================================================================================================================== */

%tmEmptySection {
  .empty-section {
    border-radius: 8px;
    background: rgba(194, 197, 203, 0.20);
    height: 300px;
    position: relative;
    margin-top: 60px;
    cursor: pointer;
    margin-right: 20px;
    margin-left: 20px;

    > button {
      width: 140px;
      position: absolute;
      top: -20px;
      right: calc(50% - 70px);
    }
  }

  .right-side {
    .empty-section {
      margin: 0 50px;
    }
  }
}

%tmPagination {
  .pagination {
    position: absolute;
    bottom: 16px;
    left: 476px;
    width: 10px;
    margin: auto;
    font-size: 13px;
  }
}

%tmPageSpace {
  .pages-space {
    height: 80px;
    @extend %flexCenterCenter;

    .page-number {
      font-size: 12px;
      line-height: 18px;
      color: rgba(48, 48, 48, 0.5);
      display: flex;
      align-items: center;
      flex-direction: column;
    }

  }

  &.tm-preview-mode {
    .pages-space {
      height: 10px;
    }
  }
}

/* ================================================================================================================== */

%sectionHover {
  transition: .3s;
  border: 1px solid;
  border-color: transparent;
  border-radius: 4px;

  &:hover {
    background-color: rgba(194, 197, 203, 0.2);
    border: 1px solid rgba(75, 87, 220, 0.2);
  }
}

%darkSectionHover {
  transition: .3s;
  border: 1px solid;
  border-color: transparent;
  border-radius: 4px;
  //z-index: 4 !important;

  &:hover {
    background-color: rgba(194, 197, 203, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}

%roundInp {
  .lng-rounds {

    --infBgColor: '#ffffff';
    --infActiveBgColor: '#4B57DC';
    --borderColor: '#4B57DC';

    @extend %flexCenter;
    margin-left: 10px;

    > div {
      width: 16px;
      height: 16px;
      border-radius: 50px;
      border: 1px solid var(--borderColor);
      cursor: pointer;
      margin-right: 8px;
      transition: 0.2s ease 0.1s;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background-color: var(--infActiveBgColor);
      }

      &.hovered {
        background-color: var(--infActiveBgColor);

        &.active {
          background-color: var(--infBgColor);
        }
      }
    }

    &.infographic-square {
      > div {
        border-radius: 4px;
      }
    }

  }
}

%tmTitleIcon {
  > i {
    font-size: 28px;
    position: absolute;
    left: -40px;
    top: 2px;
  }
}


