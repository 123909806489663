.user-delete-main-container {
  color: #000000;

  .delete-part-container {
    .top-part {

      > h2 {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 4px;
      }

      > p {
        line-height: 20px;
        margin-bottom: 0;
      }
    }

    .checkboxes-part {
      margin-bottom: 40px;

      .checkboxes {
        .sf-checkbox {
          margin-bottom: 10px;
        }
      }
    }

    .textarea-part {
      > p {
        line-height: 20px;
      }
    }

    .buttons-part {
      display: flex;
      justify-content: flex-end;
    }
  }
}
