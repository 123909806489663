@use "../global/variables" as variables;
@use "../global/mixins" as mixins;
@use "../global/placeholders" as *;

.account-main-container {
  .account-container {
    padding: 40px 0 0 0;
    max-width: 1200px;
    margin: auto;
    display: flex;

    .account-sidebar {
      border-right: 1px solid #E4E4E4;
      min-height: calc(100vh - 436px);
      flex-grow: 1;
      width: 220px;
      min-width: 220px;
      max-width: 220px;

      .account-menu-item {
        border-bottom: 1px solid #E4E4E4;
        padding: 10px 20px 10px 0;
        cursor: pointer;
        transition: .3s;
        display: block;

        &:hover, &.active {
          .ac-menu-title {
            > div {
              color: #4B57DC;

              > h3 {
                color: #4B57DC;
              }

              > i {
                color: #4B57DC;
              }
            }

            > i {
              color: #4B57DC;
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }

        .ac-menu-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: inherit;

          > div {
            @extend %flexCenter;

            > h3 {
              font-size: 20px;
              line-height: 43px;
              color: #303030;
              margin-left: 8px;
            }

            > i {
              font-weight: 700;
              color: #09090B;
            }
          }

          > i {
            margin-left: 10px;
            color: #09090B;
            font-weight: 600;
          }
        }

        > p {
          line-height: 24px;
          color: #7F7F7F;
        }


      }
    }

    .account-navbar {
      @extend %flexCenterBetween;
      margin: 0 10px 20px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: none;

      .ac-nv-link {
        flex-shrink: 0;

        .ac-nav-item {
          @extend %flexCenterCenter;
          padding-bottom: 10px;
          position: relative;

          > i {
            margin-right: 4px;
          }
        }

        &.active {
          .ac-nav-item {
            &:after {
              content: "";
              background-color: #4B57DC;
              border-radius: 4px;
              height: 3px;
              position: absolute;
              width: 100%;
              bottom: 0;
            }
          }
        }
      }
    }

    .account-content {
      flex-grow: 1;
      padding: 0 0 68px 0;
      overflow-y: hidden;
      @extend %user-page-main-container;
      @extend %security-main-container;
      @extend %billing-main-container;
      @extend %choose-plan-container;
    }
  }

  @media only screen and (max-width: variables.$largeScreen) {
    .account-container {
      .account-content {
        .choose-plan-main-container {
          .pl-bottom-part {
            flex-direction: column;

            div.pl-left-side {
              max-width: initial;
              margin-bottom: 40px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: variables.$mediumScreen) {
    .account-container {
      flex-direction: column;

      .account-sidebar {
        display: none;
      }

      .account-navbar {
        display: flex;
      }
    }
  }

  @media only screen and (max-width: variables.$smallScreen) {
    .account-container {
      flex-direction: column;

      .account-navbar {
        font-size: 12px;

        .ac-nv-link {
          .ac-nav-item {
            > i {
              display: none;
            }
          }
        }
      }

      .account-content {
        .choose-plan-main-container {
          .pl-bottom-part {
            flex-direction: column;
          }
        }
      }
    }
  }
}

.pricing-main-container {
  padding: 50px 0 0 0;
  @extend %choose-plan-container;

  @media only screen and (max-width: variables.$mediumScreen) {
    .choose-plan-main-container {
      .pl-bottom-part {
        flex-direction: column;

        div.pl-left-side {
          max-width: initial;
          margin-bottom: 40px;
          margin-right: 0;
        }
      }
    }
  }

  @media only screen and (max-width: variables.$smallScreen) {
    .choose-plan-main-container {
      .pl-bottom-part {
        flex-direction: column;
      }
    }
  }
}

%user-page-main-container {
  .user-page-main-container {
    .top-part {
      padding: 0 10px 0 10px;
      max-width: 900px;
      margin: 0 auto 92px auto;
      position: relative;

      .cover {
        height: 80px;
        background: linear-gradient(90.47deg, #25B3E0 18.28%, #4B57DC 100%);
        border-radius: 16px 16px 0 0;
      }

      .user-image {
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
        border-radius: 50px;
        cursor: pointer;

        > input {
          position: absolute;
          top: 0;
          left: 0;
          @include mixins.square(100%);
          cursor: pointer;
          opacity: 0;
        }

        .user-uploaded-img-wrapper {
          @extend %flexCenterCenter;

          .user-uploaded-img {
            border-radius: 50%;
            border: 1px solid #4B57DC;
          }
        }
      }
    }

    .form-part {
      margin-top: 32px;

      .form-top {
        padding: 0 10px 0 10px;
        max-width: 900px;
        margin: 0 auto 40px;

        > h4 {
          font-weight: 700;
          line-height: 24px;
        }

        > p {
          line-height: 24px;
          color: rgba(0, 0, 0, 0.5);
        }
      }

      > form {
        .input-part {
          padding: 0 10px 0 10px;
          max-width: 900px;
          margin: 40px auto 30px auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          > .sf-input-wr {
            width: 48%;
            flex-shrink: 0;
            margin-bottom: 20px;

            &:nth-child(odd) {
              margin-right: 2%;
            }
          }
        }

        .checkbox-part {
          padding: 0 10px 0 10px;
          max-width: 900px;
          margin: 30px auto 30px auto;

          > h3 {
            font-weight: 700;
            font-size: 20px;
            line-height: 43px;
            margin-bottom: 20px;
          }

          .checkboxes {
            line-height: 24px;

            > .sf-checkbox {
              margin-bottom: 16px;
            }
          }
        }

        .button-part {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          padding: 30px 0 0 0;

          .btn-content {
            padding: 0 10px 0 10px;
            max-width: 900px;
            margin: 0 auto;
            @extend %flexCenterBetween;


            .delete-ac {
              transition: 3s;
              cursor: pointer;
              //color: $red;

              &:hover {
                text-decoration: underline;
              }
            }

            .right-side {
              @extend %flexCenter;


              > button {
                min-width: 180px;

                &:first-child {
                  margin-right: 14px;
                }
              }
            }
          }

        }
      }
    }

    @media only screen and (max-width: variables.$largeScreen) {
      .form-part {
        > form {
          .input-part {
            .sf-input-wr {
              margin-right: 0;
              width: 100%;
            }

            .sf-phone-input {
              width: 100%;
            }
          }
        }
      }
    }

    @media only screen and (max-width: variables.$mediumScreen) {
      .form-part {
        > form {
          .button-part {
            .btn-content {
              flex-direction: column-reverse;
              align-items: self-start;

              .right-side {
                flex-direction: column;
                align-items: self-start;
                margin-bottom: 50px;
                width: 100%;

                > button {
                  width: 100%;

                  &:first-child {
                    margin-bottom: 10px;
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

%security-main-container {
  .security-main-container {
    padding: 0 0 0 10px;
    max-width: 900px;
    margin: 0 auto;

    .top-part {
      > h1 {
        font-size: 36px;
        line-height: 43px;
        color: #303030;
        border-bottom: 1px solid #E4E4E4;
        padding-bottom: 24px;
        margin-bottom: 24px;
      }
    }

    .bottom-part {
      .pass-to-change {
        @extend %flexBetween;

        .left-side {

          line-height: 24px;

          > div {
            font-weight: 700;
          }

          > p {
            color: #7F7F7F;
          }
        }

      }

      .form-wrapper {
        max-width: 380px;

        .btn-part {
          @extend %flexCenterEnd;

          > button {
            width: 130px;
          }
        }
      }

      .social-logged-in {
        > p {
          font-weight: 600;
          color: #303030;
        }
      }
    }
  }
}

%billing-main-container {
  .billing-main-container {
    padding: 0 0 0 10px;
    max-width: 900px;
    margin: 0 auto;
    overflow: auto;

    h4 {
      font-weight: 700;
      line-height: 24px;
      color: #000000;
    }

    .top-part {
      > h1 {
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 30px;
        //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      .plans-list {
        margin-bottom: 48px;
        padding-bottom: 20px;
        display: flex;
        align-items: flex-end;
        overflow: auto;

        .selected-plan {
          padding: 18px 12px;
          width: 386px;
          height: 180px;
          background-color: #4B57DC;
          border-radius: 8px;
          flex-shrink: 0;
          color: white;
          @extend %flexColumnBetween;
          margin-right: 32px;

          .top-part {
            @extend %flexBetween;

            .top-left {
              font-size: 14px;

              > strong {
                font-size: 20px;
                line-height: 26px;
              }

              > div {
                line-height: 34px;
              }
            }

            .top-right {
              > div {
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
              }
            }
          }

          .bottom-part {
            @extend %flexEndBetween;

            button {
              width: 204px;
            }

            .canceled {
              font-weight: 500;
              font-size: 18px;
            }
          }

          .free-plan {
            ul {
              margin-bottom: 0;
            }
          }

          .free-plan-desc {
            font-size: 13px;
            margin-left: 22px;
          }
        }

        .plan-item {
          width: 360px;
          height: 160px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          padding: 10px 12px;
          flex-shrink: 0;
          margin-right: 32px;
          @extend %flexColumnBetween;

          .plan-top {
            @extend %flexBetween;
            margin-bottom: 30px;

            .plan-top-left {
              > h4 {
                font-size: 18px;
                font-weight: bold;
              }
            }

            .plan-top-right {
              .total-pr {
                display: flex;
                justify-content: flex-end;

                > span {
                  margin-right: 6px;
                }
              }
            }
          }

          .plan-bottom {
            @extend %flexCenterBetween;

            > button {
              width: 160px;
            }
          }
        }
      }
    }

    .payments-part {
      .billing-list {
        margin-bottom: 30px;
        margin-top: 10px;

        .payment-method {
          color: #FFFFFF;
          font-weight: 600;
          font-size: 12px;
          position: relative;
          margin-top: 24px;

          > span {
            position: absolute;

            &.name-surname {
              bottom: 72px;
              left: 32px;
              font-size: 12px;
              text-transform: uppercase;
            }

            &.active-until {
              bottom: 42px;
              left: 208px;
            }

            &.card-numbers {
              letter-spacing: 0.4px;
              line-height: 20px;
              bottom: 42px;
              left: 32px;

              > span {
                margin-right: 4px;
              }
            }

          }

          > i {
            position: absolute;
            color: white;
            left: 300px;
            font-size: 18px;
            background-color: #4B57DC;
            padding: 9px 9px;
            border-radius: 50px;
            cursor: pointer;
            top: 80px;
            border: 2px solid;
          }
        }
      }

    }

    .billing-history-part {
      > h4 {
        margin-bottom: 24px;
      }

      .table-wrapper {
        //min-width: 900px;
        min-width: 1000px;

        .table-actions {
          display: flex;

          i {
            font-size: 20px;
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
    }


    @media only screen and (max-width: variables.$smallScreen) {
      padding: 0;

      .billing-history-part {
        .table-wrapper {

          .download-txt {
            display: none;
          }

          .ph.ph-download-simple {
            //display: block;
          }
        }
      }
    }

  }
}

%choose-plan-container {
  .choose-plan-main-container {
    padding: 0 16px 0 16px;
    max-width: 900px;
    margin: 0 auto;
    color: #303030;

    .pl-top-part {
      > h1 {
        font-size: 36px;
        line-height: 43px;
        //border-bottom: 1px solid #E4E4E4;
        margin-bottom: 0;
        padding-bottom: 24px;
      }
    }

    .pl-bottom-part {
      display: flex;
      justify-content: space-between;

      .pl-left-side {
        max-width: 342px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin-right: 24px;
        padding: 16px 16px;
        position: relative;

        .ls-top-part {
          margin-bottom: 48px;

          > h3 {
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
          }

          .free-pl {
            position: absolute;
            min-width: 146px;
            height: 79px;
            right: 0;
            top: 0;
            background: #4B57DC;
            color: white;
            border-radius: 0 8px;
            line-height: 28px;
            font-size: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 25px;
            padding-right: 10px;
            letter-spacing: 0.2px;

            > p {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }

        .ls-bottom-part {
          @extend %plan-includes;
        }
      }
    }

    %plan-includes {
      .pl-includes {
        > h3 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 24px;
        }

        > ul {
          padding-left: 40px;
          list-style: initial;

          li {

          }
        }
      }
    }

  }
}

