$smallScreen: 576px;
$mediumScreen: 768px;
$largeScreen: 992px;
$extraLarge: 1200px;

$white: var(--white);
$black: var(--black);
$red: var(--red);
$purple: var(--purple);

$h1Size: var(--h1Size);
$h2Size: var(--h2Size);
$h3Size: var(--h3Size);
$h4Size: var(--h4Size);
$h5Size: var(--h5Size);

$borderRadiusL: var(--borderRadiusL);
$borderRadiusM: var(--borderRadiusM);
$borderRadiusS: var(--borderRadiusS);

$shadowSoft: var(--shadowSoft);
$shadowMid: var(--shadowMid);
$shadowStrong: var(--shadowStrong);
$shadowStronger: var(--shadowStronger);

$sfFontFamily: var(--sfFontFamily);

$fontWeight: var(--fontWeight);
$fontWeightLight: var(--fontWeightLight);
$boldFont: var(--boldFont);
$bolderFont: var(--bolderFont);
$boldestFont: var(--boldestFont);


$maxWidth: var(--maxWidth);

:root {
  --white: #ffffff;
  --black: #000000;
  --purple: #4B57DC;
  --green: #16DEAE;
  --blue: #00A3FF;
  --lightGray: #F9FAFB;
  --darkGray: #5A5D63;
  --red: #E25B5C;

  --fontSize: 16px;
  --lineHeight: 22px;

  --h1Size: 36px;
  --h2Size: 22px;
  --h3Size: 18px;
  --h4Size: 16px;
  --h5Size: 12px;

  --borderRadiusL: 16px;
  --borderRadiusM: 8px;
  --borderRadiusS: 4px;

  --shadowSoft: 0 3px 4px -2px rgba(40, 25, 45, 0.02);
  --shadowMid: 0 2px 4px rgba(40, 25, 45, 0.03);
  --shadowStrong: 0 3px 8px rgba(40, 25, 45, 0.04);
  --shadowStronger: 0 3px 16px rgba(40, 25, 45, 0.08);

  --sfFontFamily: 'Poppins', sans-serif;
  --fontWeightLight: 300;
  --fontWeight: 400;
  --boldFont: 500;
  --bolderFont: 600;
  --boldestFont: 700;

  --smallScreen: 576px;
  --mediumScreen: 768px;
  --largeScreen: 992px;
  --extraLarge: 1200px;

  --maxWidth: 1180px;
}
