@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(4px, 0);
  }
}
