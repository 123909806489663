@use "../global/variables" as variables;
@use "../global/bg-images" as bg_images;
@use "../global/placeholders" as *;

.auth-main-container {
  @include bg_images.bgImage('vector-half');
  background-position: top right;

  @media only screen and (max-width: variables.$extraLarge) {
    background-size: 116%;
  }

  @media only screen and (max-width: variables.$largeScreen) {
    @include bg_images.bgImage('complete-vector');
    .auth-container {

      &.sign-in, &.sign-up {
        background-image: none;
      }

      .left-side {
        width: 100%;

        .content-part {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          padding: 40px 20px 40px 20px;
          max-width: 400px;
          background-color: white;
        }

      }

      .right-side {
        display: none;
      }
    }
  }

  @media only screen and (max-width: variables.$mediumScreen) {
    background-image: none;
  }

}

.auth-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 20px;

  h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 26.4px;
    margin-bottom: 4px;
  }

  &.sign-in {
    @include bg_images.bgImage('sign-in-cv', '', 'png');
  }

  &.sign-up {
    @include bg_images.bgImage('sign-up-cv', '', 'png');
  }

  &.forgot-password {
    @include bg_images.bgImage('complete-vector');

    .left-side {
      .content-part {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 40px 20px 40px 20px;
        max-width: 420px;
        background-color: white;
      }
    }
  }

  &.sign-in, &.sign-up {
    background-position: 96% 50%;
    background-size: clamp(100px, 48%, 635px);
    max-width: 1600px;
    margin: 0 auto;
  }

  .left-side {
    text-align: center;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    width: 50%;
    justify-content: center;

    .content-part {
      flex-grow: 1;
      max-width: 380px;

      .sub-title {
        line-height: 24px;
        color: #858585;
        margin-bottom: 30px;
      }

      .logo-wrapper {
        @extend %flexCenterCenter;
        margin: 0 auto 32px auto;
      }

      .form-wrapper {
        form {

          .forgot-pass {
            @extend %flexCenterEnd;
            margin-bottom: 32px;
            margin-top: 8px;
          }

          .sf-checkbox {
            text-align: left;
          }

          .have-not-account {
            margin-top: 20px;
            @extend %flexCenterBetween;
            color: #808080;
          }
        }
      }

      .email-send-banner {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #858585;

        > h2 {
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 4px;
        }

        > p {
          margin-bottom: 32px;
        }

        .have-not-account {
          @extend %flexCenterBetween;
        }
      }

    }

  }

  .right-side {
    flex-grow: 1;
    width: 50%;
  }

  @media only screen and (max-width: variables.$mediumScreen) {
    &.forgot-password {
      background-image: none;
    }
  }

}

