@use "../../global/placeholders" as *;

.template-main-container {
  /*%sectionHover2 {
    transition: .3s;
    border: 1px solid;
    border-color: transparent;
    border-radius: 4px;

    &:hover {
      background-color: rgba(194, 197, 203, 0.2);
      border: 1px solid rgba(75, 87, 220, 0.2);
    }
  }*/


  &.template-id-5 {

    &.tm-type-sidebar {
      .template-page-parent {

        .sidebar-top-part-2 {

          .tm-top-wrapper {

            h3 {
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              z-index: 2;
            }

            .social-name-img-wr {
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: var(--sidebarPdTop) 0 20px 0;

              h3 {
                padding: 0 2px;
                z-index: 1;
                position: relative;
                //@extend %sectionHover2;
              }

              .top-left-side {
                display: flex;
                padding-left: 50px;

                .name-sr-name-part {
                  margin-right: 24px;
                  min-width: 200px;

                  h3 {
                    padding: 0 2px;
                    position: relative;
                    z-index: 1;
                    //@extend %sectionHover2;
                  }
                }

                .img-part-wrapper {
                  padding: 0;
                  margin-bottom: 10px;

                  .tm-st-banner {
                    .tm-st-banner-settings {
                      &.img-settings {
                        left: 150px;
                        right: initial;
                      }
                    }
                  }
                }
              }
            }

            &.tm-top-wrapper-type-5 {
              .top-left-side {

                .img-part-wrapper {
                  .img-part {
                    margin-right: 50px;
                  }
                }

                .name-part {
                  padding-right: 50px;
                }

                .summary-part {
                  margin: 10px 50px 4px 0;
                  font-size: 14px;
                }
              }
            }
          }

          .edit-mode {
            background-color: white !important;
            color: #303030;
            position: relative;
            z-index: 4 !important;
            border-radius: 4px;

            > h2 {
              color: inherit;
            }
          }
        }

        .template-page {
          .left-side {
            border-radius: 4px 0 4px 0;
          }

          .right-side {
            .social-info {
              &.on-sidebar-right-side-top {
                border-radius: 0 8px 8px 0;
                margin-bottom: 16px;
                flex-grow: 2;
                padding: 12px 20px 12px 50px;
                height: fit-content;
                min-width: 366px;
                cursor: pointer;
                transition: .3s;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                font-size: 13px;
                width: calc(100% - 20px);

                ul {
                  margin: 0;
                  padding: 0;
                  list-style-type: none;
                  @extend %flexCenter;
                  flex-wrap: wrap;

                  li {
                    word-break: break-word;
                    word-wrap: break-word;

                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    margin-bottom: 4px;
                    margin-top: 4px;
                    pointer-events: none;
                    @extend %flexStart;
                    margin-right: 20px;

                    &:last-child {
                      margin-right: 0;
                    }

                    > a {
                      color: inherit;
                    }

                    > i {
                      margin-right: 4px;
                      line-height: inherit;
                    }

                  }
                }

                &:hover {
                  opacity: .8;
                }
              }

            }
          }
        }

        &.page-1 {
          .template-page {
            .right-side {
              padding-top: 0;
            }
          }
        }
      }

      &.sidebar-position-left {
        .template-page-parent {
          .template-page {
            .left-side {
              border-radius: 0 4px 0 4px;
            }

            .right-side {
              .social-info {
                &.on-sidebar-right-side-top {
                  border-radius: 8px 0 0 8px;
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }

      &.sidebar-position-right {
        .template-page-parent {
          .template-page {
            .right-side {
              .right-side-body {
                .list-section {
                  padding: 0 30px 0 48px;
                }
              }
            }
          }
        }
      }
    }

    .work-experience-part
    .work-experience-list
    .work-experience-item
    .resp-area
    ul {
      list-style-type: circle;
    }

  }
}

