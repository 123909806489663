.sf-cover-letter-template {
  &.template-id-3 {
    .tm-top-wrapper {
      &.tm-top-wrapper-type-4 {
        .top-left-side {
          .name-part {
            h3 {
              border-radius: 0 8px 8px 0;
              padding-top: 6px;
              padding-bottom: 6px;
            }
          }
        }
      }
    }
  }
}
