@use "global";
@use "elements";
@use "pages";
@use "modals";
@use "templates";

.main-layouts-container {
  h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
  }

  h2 {
    font-weight: 500;
    font-size: 30px;
  }

  .blue-small-desc {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: #16AEDE;
    margin-bottom: 2px;
    letter-spacing: 0.2px;
  }

  .title-top-desc {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
  }
}
