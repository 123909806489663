@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin backgroundImage($state: contain) {
  @extend %backgroundImage;
  background-size: $state;
}

@mixin btnColor($color, $background_color, $border_color) {
  border: 1px solid $border_color;
  background-color: $background_color;
  color: $color;
}

@mixin btnSize($width, $height, $border: none) {
  width: $width;
  height: $height;
  border: $border;
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin scroll-bar($width: 4px, $radius: 4px) {
  & {
    scrollbar-width: thin;
    scrollbar-color: #AEAEB5 #F0F0F4;
  }

  &::-webkit-scrollbar {
    @include square($width);
    border-radius: $radius;
  }

  &::-webkit-scrollbar-track {
    background-color: #F0F0F4;
    border-radius: $radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #AEAEB5;
    border-radius: $radius;
    transition: all .2s linear;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #E5E5EA;
  }
}

@mixin absoluteCenter($width) {
  width: $width;
  @extend %absoluteCenter;
}

@mixin backdrop($position: fixed, $z-index: 99) {
  @extend %backdrop;
  position: $position;
  z-index: $z-index;
}

