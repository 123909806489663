@use "../global/placeholders" as *;

.paid-feature-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  @extend %flexCenterBetween;
  gap: 40px;
  background: rgba(75, 87, 220, .8);
  color: white;
  z-index: 9;
  padding: 6px 90px 6px 30px;

  .bn-text-info {
    font-weight: 600;
    margin-left: 2px;
  }

  button {
    border-radius: 8px;
    border: none;
    background: #FFF;
    color: #303030;
    padding: 0 10px;
    height: 38px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex-shrink: 0;
  }

  .icon-bnt {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 22px;
    }
  }

  @media only screen and (max-width: 796px) {
    .bn-text-info {
      display: none;
    }
  }

  @media only screen and (max-width: 715px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 6px 20px 6px 20px;
  }
}

.paid-banner-title {
  height: 48px;
  background-color: #4B57DC;
  color: white;
  padding: 0 50px;

  button {
    border-radius: 8px;
    border: none;
    background: #FFF;
    color: #303030;
    padding: 0 10px;
    height: 38px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex-shrink: 0;
  }

  .icon-bnt {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 22px;
    }
  }

  @media only screen and (max-width: 650px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 500px) {
    padding: 0 16px;
    font-size: 14px;

    button {
      width: 110px;
      font-size: 13px;
    }
  }

}

.selected-paid-features {
  padding: 0 0 0 30px;

  .sf-btn-bg-none {
    color: #4B57DC;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    > li {
      min-width: 300px;
      border-right: 1px solid #0000001A;
      border-bottom: 1px solid #0000001A;
      padding: 0 30px;

      &:nth-child(3n) {
        border-right: none;
      }

      &:nth-child(n+4) {
        border-bottom: none;
      }

      &:nth-child(3n - 2) {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
      }

      .feature-title {
        min-width: 170px;
        font-weight: 500;
        height: 48px;
        display: flex;
        align-items: center;
      }
    }

  }

  @media only screen and (max-width: 974px) {
    padding: 0;

    ul {
      grid-template-columns: repeat(2, 1fr);

      > li {
        &:nth-child(3n) {
          border-right: 1px solid #0000001A;
        }

        &:nth-child(2n) {
          border-right: none;
        }

        &:nth-child(n+4) {
          border-bottom: 1px solid #0000001A;
        }

        &:nth-child(n+5) {
          border-bottom: none;
        }

        &:nth-child(3n - 2) {
          padding-left: 30px;
        }

        &:nth-child(2n - 1) {
          padding-left: 0;
        }
      }

    }
  }

  @media only screen and (max-width: 650px) {
    padding: 0 20px;

    ul {
      &.paid-features-list {
        grid-template-columns: repeat(1, 1fr);

        > li {
          padding: 0;
          margin: 0;
          border-right: none;
          border-bottom: 1px solid #0000001A;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
