.social-info-sidebar-settings-wr {

  .move-to-sidebar {
    > div {
      cursor: pointer;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .social-info-container {

  }
}

.tm-social-info-item-drg {
  position: relative;
  margin-bottom: 10px;

  .ph-dots-six-vertical {
    position: absolute;
    left: -18px;
    top: 10px;
    font-size: 18px;
    //display: none;
    cursor: move;
  }

  /*&:hover {
    .ph-dots-six-vertical {
      display: block;
    }
  }*/

  &.cdk-drag-placeholder {
    opacity: .5;
  }

  .sf-input2-wr {
    .right-i {
      padding: 12px 10px;
      cursor: pointer;
      top: 0;
      right: 0;
    }
  }

}
