@use "../../global/placeholders" as *;

.sf-cover-letter-template {
  --tm-cover-link-color: #4b57dc;
  --before-after-color: #4B57DC;

  position: relative;
  width: 952px;
  //height: 1348px;
  height: 1346px;
  box-shadow: 0 0 10px #0000001a;
  background-color: #fff;
  border-radius: 4px;
  padding-bottom: 50px;
  margin: 0;
  font-size: 14px;
  line-height: 21px;

  .template-overlay {
    position: absolute;
    background: rgba(52, 64, 84, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 4px;
  }

  [contenteditable] {
    outline: 0 solid transparent;
    cursor: text;
    min-height: 10px;
    word-break: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  [contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    width: auto;
    opacity: .5;
  }

  [contenteditable][placeholder]:empty:focus:before {
    content: attr(placeholder);
    width: auto;
    opacity: .2;
  }

  a {
    color: var(--tm-cover-link-color);
    text-decoration: underline;
    cursor: initial;

    &:hover {
      text-decoration: initial;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    padding: 0 2px;
    position: relative;
    z-index: 1;
    @extend %cover_sectionHover;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 4px;
    border-radius: 4px;
    padding: 0 2px;
    position: relative;
    z-index: 1;
    @extend %cover_sectionHover;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    color: #4B57DC;
    border-radius: 4px;
    padding: 0 2px;
    position: relative;
    z-index: 1;
    font-style: normal;
    font-weight: 700;
    @extend %cover_sectionHover;
  }

  .body-part {
    padding: 0 48px;

    .to-whom {
      position: relative;
      z-index: 1;
      padding: 0 2px;
      border-radius: 4px;
      @extend %cover_sectionHover;

      > ul {
        > li {
          margin-bottom: 4px;

          nz-date-picker {
            width: 200px;
          }
        }
      }
    }

    .cover-content {
      .mr-mrs, .c-content {
        position: relative;
        z-index: 1;
        padding: 0 2px;
        border-radius: 4px;
        @extend %cover_sectionHover;
      }

      .mr-mrs {
        margin-bottom: 20px;
      }

      .from-me {
        margin-top: 30px;
        position: relative;
        z-index: 1;
        border-radius: 4px;

        .cover-sender {
          @extend %cover_sectionHover;
        }

        .cover-sender, .cover-receiver {
          line-height: 23px;
          padding: 0 2px;
          border: 1px solid transparent;
        }

      }

    }
  }

  .tm-st-banner {
    height: 50px;
    padding: 6px;
    top: -60px;
    left: 0;
    z-index: 5;
    position: absolute;
    width: fit-content;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;


    &.c-details {
      height: initial;
      padding: 4px 6px;
      top: -48px;
      left: -2px;

      .tm-st-banner-settings {
        &.work-exp {
          top: 0;
          right: -265px;
        }
      }
    }

    > i {
      font-size: 22px;
      padding: 6px;
      cursor: pointer;
      transition: .3s;
      border-radius: 4px;
      color: #303030;

      &:hover, &.active {
        background-color: #ebebeb;
      }

    }

    .new-entry {
      position: relative;
      background: #4B57DC;
      padding: 6px 10px;
      border-radius: 8px;
      color: white;
      margin-right: 6px;
      cursor: pointer;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-self: center;

      > i {
        padding: 0;
        margin-right: 8px;
        color: white;
        font-size: 22px;
      }
    }

    .tm-st-banner-settings {
      //z-index: 3;

      &.img-settings {
        padding: 16px;
        position: absolute;
        background-color: white;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        top: 56px;
        left: 180px;
        display: flex;

        .img-shape-icon-wr {
          margin-right: 16px;
          position: relative;

          &:last-child {
            margin-right: 0;
          }

          .ph-star {
            position: absolute;
            z-index: 1;
            top: -12px;
            right: -6px;
            color: #4b57dc;
          }

          .img-shape {
            border: 2px solid #303030;
            width: 5px;
            height: 5px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #303030;
            cursor: pointer;
            border-radius: 4px;

            &:hover, &.active {
              background-color: initial;
              border-color: #4b57dc;
              color: #4b57dc;
            }

            &.round {
              border-radius: 100px;
            }

            &.left-top {
              border-radius: 20px 50px 50px 50px;
            }

            &.right-top {
              border-radius: 50px 20px 50px 50px;
            }

            &.left-bottom {
              border-radius: 50px 50px 50px 20px;
            }

            &.right-bottom {
              border-radius: 50px 50px 20px 50px;
            }
          }
        }
      }

      &.work-exp {
        top: 52px;
        right: -150px;
        position: absolute;
        min-width: 200px;
        border: 1px solid lightgray;
        border-radius: 8px;
        background: #FFF;
        padding: 16px 16px 16px 14px;

        .checkboxes-container {
          .sf-checkbox {
            margin-bottom: 6px;
            text-transform: capitalize;
            border-radius: 4px;
            border: 1px solid transparent;
            padding: 2px 4px;

            label {
              white-space: nowrap;
            }

            &:hover {
              border: 1px solid rgba(75, 87, 220, 0.2);
              background: rgba(194, 197, 203, 0.2);
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

  }

  .edit-mode {
    background-color: white !important;
    color: #303030;
    position: relative;
    z-index: 4 !important;
    border-radius: 4px;

    > h2 {
      color: inherit;
    }
  }

  .tm-top-wrapper {
    padding-top: 50px;
    margin-bottom: 40px;

    .social-name-img-wr {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: var(--sidebarPdTop) 0 20px 0;

      .top-left-side {
        display: flex;
        padding-left: 50px;

        .name-sr-name-part {
          margin-right: 24px;
          min-width: 200px;
        }
      }
    }

    .social-info {
      border-radius: 8px 0 0 8px;
      flex-grow: 2;
      padding: 12px 20px 12px 50px;
      height: fit-content;
      min-width: 366px;
      cursor: pointer;
      //transition: .3s;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      font-size: 13px;
      margin-top: 16px;
      width: 100%;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        @extend %flexCenter;
        flex-wrap: wrap;

        > a {
          text-decoration: none;
          color: inherit;
          cursor: pointer;
          margin-right: 20px;
        }

        li {
          word-break: break-word;
          word-wrap: break-word;

          font-style: normal;
          font-weight: 400;
          //margin-bottom: 4px;
          margin-bottom: 8px;
          margin-top: 4px;
          pointer-events: none;
          display: flex;
          align-items: flex-start;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          > a {
            color: inherit;
          }

          > i {
            margin-right: 4px;
            line-height: inherit;
          }

        }
      }

      &:hover {
        opacity: .8;
      }
    }

    &.tm-top-wrapper-type-2 {
      .social-name-img-wr {
        flex-direction: row;
        justify-content: space-between;

        .social-info {
          margin-top: 0;
          padding-left: 20px;
          max-width: 460px;
        }
      }
    }

    &.tm-top-wrapper-type-3 {
      margin-bottom: 24px;
      padding-top: 0;
      border-radius: 4px 4px 0 0;

      .img-part-wrapper {
        margin-right: 0;
        margin-left: 30px;
      }

      .social-name-img-wr {
        flex-direction: row;
        justify-content: space-between;
        padding: 50px 48px 16px 48px;

        .top-left-side {
          flex-direction: column;
          padding-left: 0;

          .social-info {
            margin-top: 8px;
            padding-left: 0;
          }
        }

        .img-part-wrapper {
          .img-part {
            margin-right: 0;
          }

          .tm-st-banner {
            left: initial;
            right: 0;

            .tm-st-banner-settings {
              left: initial;
              right: 174px;
            }
          }
        }

      }
    }

    &.tm-top-wrapper-type-4 {
      padding-top: 0;


      .social-name-img-wr {
        flex-direction: row;
        justify-content: space-between;

        .top-left-side {
          padding-left: 0;
          padding-top: 50px;

          .img-part-wrapper {
            margin-left: 50px;
          }

          .name-part {
            h1 {
              padding-left: 48px;
            }

            h3 {
              padding: 2px 16px 2px 48px;
              border-radius: 0 2px 2px 0;
            }
          }
        }

        .social-info {
          margin-top: 0;
          max-width: 460px;
          border-radius: 0 0 0 8px;
          padding: 16px 30px 16px 20px;
        }
      }

      &.has-image {
        .social-name-img-wr {
          .top-left-side {
            .name-part {
              h1 {
                padding-left: 2px;
              }

              h3 {
                padding: 2px 12px 2px 12px;
              }
            }
          }
        }
      }
    }

    &.tm-top-wrapper-type-5 {
      .top-left-side {
        flex-direction: row-reverse;

        .img-part-wrapper {
          .img-part {
            margin-right: 50px;
          }
        }

        .name-part {
          padding-right: 24px;
        }

        .summary-part {
          margin: 10px 24px 4px 0;
        }
      }
    }

    &.tm-top-wrapper-type-6 {
      .name-part {
        padding-right: 48px;
      }

      .summary-part {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 4px;
      }

      .social-info {
        position: relative;
        background-color: transparent !important;
        color: rgb(48, 48, 48);
        border-top: 1px solid var(--before-after-color);
        border-bottom: 1px solid var(--before-after-color);;
        margin-right: 50px;
        margin-left: 50px;
        width: calc(100% - 100px);
        border-radius: 0;
        padding: 8px 0 8px 0;

        &:before, &:after, ul:before, ul:after {
          content: "";
          width: 6px;
          height: 6px;
          background-color: var(--before-after-color);;
          position: absolute;
          border-radius: 50px;
        }

        &:before {
          left: -3px;
          top: -3px;
        }

        &:after {
          left: -3px;
          bottom: -3px;
        }

        ul {
          &:before {
            right: -3px;
            top: -3px;
          }

          &:after {
            right: -3px;
            bottom: -3px;
          }

          li {
            color: #303030;
          }
        }

      }

    }

    &.tm-top-wrapper-type-7 {
      .social-name-img-wr {
        flex-direction: row;
        justify-content: space-between;

        .top-left-side {
          .name-part {
            h3 {
              border-radius: 2px;
            }
          }
        }

        .social-info {
          margin-top: 0;
          display: flex;
          justify-content: flex-end;
          background-color: transparent;
          padding: 0 50px 0 20px;
          max-width: 370px;

          ul {
            flex-wrap: initial;
            flex-direction: column;
            align-items: flex-start;

            li {
              margin-right: 0;
            }
          }
        }
      }

      .social-info {
        border-radius: 2px;

        &.on-side {
          margin-bottom: 20px;

          ul {
            color: #303030;
          }
        }
      }
    }
  }

  %cover_sectionHover {
    transition: .3s;
    border: 1px solid;
    border-color: transparent;
    border-radius: 4px;

    &:hover {
      background-color: rgba(194, 197, 203, 0.2);
      border: 1px solid rgba(75, 87, 220, 0.2);
    }
  }

}
