@use "placeholders" as *;
@use "mixins";
$globalIconPath: '/assets/img/icons/';

%iconStyles {
  @include mixins.square(16px);
  display: inline-block;
  @extend %bgImageContain;
}

i[class^='sf-icon'], i[class*='sf-icon'] {
  @include mixins.square(16px);
}

@mixin bgIcon($class, $iconType: 'svg') {
  background-image: url("#{$globalIconPath}#{$class}.#{$iconType}");
  @extend %iconStyles;
}

$iconsArray: (
    ('ATS-friendly'),
    ('editing'),
    ('freemium'),
    ('live-content'),
    ('premium'),
    ('pricing'),
    ('download'),
    ('fill-blanks'),
    ('icon-logo'),
    ('layout-customize'),
    ('pick-template'),
    ('white-star'),
    ('zoom-btn'),
    ('google'),
    ('icon-search'),
    ('menu-kebab-light'),
    ('menu-kebab-dark'),
    ('skype-black'),
    ('skype-white'),
    ('one-line'),
    ('two-lines'),
    ('three-lines'),
);

@each $class, $hover, $type in $iconsArray {
  $iconType: $type;

  @if $type == null {
    $iconType: 'svg';
  }

  .sf-icon-#{$class} {
    @include bgIcon($class, $iconType);

    &:hover {
      @if $hover != null {
        @include bgIcon($hover);

        &.skip-hover {
          @include bgIcon($class, $iconType);
        }

      }
    }

  }

}
