@use "../global/mixins" as mixins;
@use "../global/placeholders" as *;

.builder-main-container {
  .builder-content {
    padding: 40px 10px 40px 10px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .bl-sidebar {
      position: sticky;
      top: 100px;
      height: 440px;

      .back-to-document {
        margin-bottom: 40px;
        font-size: 14px;
        width: fit-content;

        > a {
          @extend %flexCenter;

          > i {
            margin-right: 4px;
          }
        }
      }

      .tm-top {
        @extend %flexCenter;
        margin-bottom: 28px;

        > button {
          border: 1px solid #AEAEB5;
          border-radius: 8px;
          @include mixins.square(40px);
          @extend %flexCenterCenter;
          cursor: pointer;
          padding: 0;
          background-color: white;

          &:last-child {
            margin-left: 6px;
          }

          > i {
            font-size: 22px;
          }
        }
      }

      .template-actions {
        width: 208px;
        padding: 26px 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        font-size: 15px;
        background-color: white;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;

          .upload-existing-cv {
            margin-bottom: 12px;
          }

          li {
            margin-bottom: 12px;
            cursor: pointer;
            min-height: 38px;
            border-radius: 4px;
            padding: 0 3px;
            width: 182px;
            font-size: 15px;
            @extend %flexCenter;
            color: black;

            > i {
              margin-right: 6px;
              margin-left: 4px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              background-color: #F0F0F4;
            }
          }
        }
      }
    }

    .template-wrapper {
      margin-left: 20px;

      .template-container {
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    .builder-content {
      .bl-sidebar {

        .template-actions {
          //display: none;

          width: 58px;
          padding: 10px 12px;
          border: 1px solid rgba(0, 0, 0, .1);
          border-radius: 8px;

          > ul {
            li {
              width: fit-content;

              i {
                font-size: 20px;
              }

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1150px) {
    .builder-content {
      transform: scale(0.9);
      transform-origin: top;
      flex-direction: column;
      align-items: center;

      .bl-sidebar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: initial;
        position: initial;
        width: 952px;
        margin-bottom: 10px;

        .back-to-document {
          font-size: 16px;
          margin-bottom: 0;
        }

        .tm-top {
          > button {
            @include mixins.square(36px);
          }
        }

        .template-actions {
          width: initial;

          > ul {
            display: flex;

            .upload-existing-cv {
              margin-bottom: 0;
            }

            li {
              width: fit-content;
              margin-bottom: 0;
              height: 30px;

              i {
                font-size: 22px;
                margin-right: 10px;
                margin-left: 10px;
              }
            }
          }
        }
      }

      .template-wrapper {
        margin-left: 0;

        .template-container {
        }
      }
    }
  }

  @media only screen and (max-width: 940px) {
    .builder-content {
      transform: scale(0.8);

      .bl-sidebar {
        .back-to-document {
          font-size: 18px;
          //margin-bottom: 32px;
        }

        .template-actions {
          > ul {
            li {
              i {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 840px) {
    .builder-content {
      transform: scale(0.6);

      .bl-sidebar {
        .back-to-document {
          font-size: 18px;
          //margin-bottom: 28px;
        }

        .template-actions-icons {
          > ul {
            li {
              i {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }

}

.template-design-sidebar-container {
  .tm-ds-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
  }

  .tm-background {
    .tm-backgrounds-list {
      @extend %flexCenterCenter;
      flex-wrap: wrap;
      gap: 6px;

      > img {
        cursor: pointer;

        &:hover, &:active {
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }

  .tm-font-size {
    .font-size-items {
      @extend %flexCenterBetween;

      > div {
        width: 80px;
        height: 28px;
        @extend %flexCenterCenter;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          border: 1px solid #4B57DC;
          color: #4B57DC;
        }

        &.active {
          border: 1px solid #4B57DC;
          color: #4B57DC;
        }
      }

      > .sm {
        font-size: 14px;
      }

      > .md {
        font-size: 16px;
      }

      > .lr {
        font-size: 18px;
      }
    }
  }
}

.sidebar-s, .two-row-r, two-row-l {
  .rr-section-item {
    padding: 0 12px;
  }
}

.rr-section-item {
  border: 1px solid #E7E7E7;
  border-radius: 8px;
  margin-bottom: 10px;
  //width: 148px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  background-color: #FBFBFB;
  position: relative;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
    text-align: center;
  }

  .ph-arrows-out-cardinal {
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 12px;
  }

  .ph-x {
    position: absolute;
    padding: 8px;
    top: 0;
    right: 0;
    display: none;
    cursor: pointer;
  }

  &.cdk-drag-placeholder {
    opacity: .5;
  }

  &:last-child {
    //margin-bottom: 0;
  }

  &:hover {
    background-color: #E6E6E6;
    border-color: #9BA0D1;

    .ph-x {
      display: block;
    }
  }

}

