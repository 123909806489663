@use "../global/placeholders" as *;
@use "../global/icons" as icons;

@mixin sf-dropdown-scroll-bar($width: 5px, $radius: 2px) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
    border-radius: $radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(102, 102, 102, 0.3);
    border-radius: 20px;
    transition: all .2s linear;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  }
}

.sw-dropdown {
  font-family: Popins, sans-serif;

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

  &.has-icons {
    .dropdown-container {
      .selected-item {
        > span {
          @extend %flexCenter;

          &:before {
            margin-right: 8px;
          }
        }
      }

      .dropdown-items-list {
        .items-container {
          .all-dropdown-items {
            .dropdown-item-el {
              &:before {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .sw-dropdown-label {
    color: #383B3D;
    margin-bottom: 8px;
  }

  .dropdown-container {
    position: relative;
    height: 42px;

    .selected-item {
      @extend %flexCenterBetween;
      padding: 0 16px;
      cursor: pointer;
      left: 0;
      background-color: #ffffff;
      border: 1px solid #E5E5EA;
      border-radius: 8px;
      height: 100%;
      @extend %ellipsis;

      .inner-text {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;

        &::first-letter {
          text-transform: capitalize;
        }

        &.sw-placeholder {
          color: #AEAEB5;
        }
      }

    }

    .dropdown-items-list {
      background: #ffffff;
      border: 1px solid #E5E5EA;
      box-sizing: border-box;
      box-shadow: 0 3px 8px rgba(40, 25, 45, 0.04);
      border-radius: 0 0 8px 8px;
      padding: 16px;
      position: absolute;
      width: 100%;
      z-index: 9;
      top: calc(100% - 1px);

      .sw-search-container {
        position: relative;
        width: 100%;
        margin: auto auto 10px;

        .sw-input-search {
          width: 100%;
          padding: 0 28px 0 5px;
          border: solid 1px rgba(175, 179, 197, 0.5);
          border-radius: 4px;
          color: inherit;
          height: 30px;
          font-size: 13px;

          &:focus {
            border: 1px solid #afb3c5;
          }
        }

        &::after {
          content: '';
          position: absolute;
          right: 10px;
          top: 7px;
          @include icons.bgIcon('icon-search');
        }
      }

      .items-container {

        .all-dropdown-items {
          overflow: auto;
          max-height: 190px;
          @include sf-dropdown-scroll-bar();

          .unset {
            color: #AEAEB5 !important;
          }

          .dropdown-item-el {
            height: 38px;
            display: flex;
            align-items: center;
            font-style: normal;
            font-size: 13px;
            line-height: 22px;
            color: #383B3D;
            @extend %ellipsis;
            padding-left: 8px;
            cursor: pointer;
            border-radius: 8px;
            margin-right: 8px;


            &:hover {
              background: #FAFAFC;
            }

            &::first-letter {
              text-transform: capitalize;
            }

          }

        }

      }
    }

    &.active {
      .selected-item {
        border-radius: 8px 8px 0 0;

        .chevron-down {
          transform: rotate(180deg);
        }
      }

      &.element-open-top {
        .selected-item {
          border-radius: 0 0 8px 8px;
        }

        .dropdown-items-list {
          bottom: 41px;
          top: initial;
          border-radius: 8px 8px 0 0;
        }

        &:hover {
          .dropdown-items-list {
            bottom: 42px;
            border-bottom: initial;
            border-top: 1px solid #AEAEB5;
          }
        }
      }

    }

    &:hover {
      .selected-item {
        border: 1px solid #AEAEB5;
      }

      .dropdown-items-list {
        border-right: 1px solid #AEAEB5;
        border-bottom: 1px solid #AEAEB5;
        border-left: 1px solid #AEAEB5;
      }
    }

  }

  &.error {
    .dropdown-container {
      .selected-item {
        border: 1px solid #E25B5B;
      }

      .dropdown-items-list {
        border-right: 1px solid #E25B5B;
        border-bottom: 1px solid #E25B5B;
        border-left: 1px solid #E25B5B;
      }
    }
  }

  &.gray {
    .dropdown-container {
      .selected-item {
        background-color: #FAFAFC;
      }
    }
  }

  &.label-small-font {
    .sw-dropdown-label {
      font-size: 11px;
      line-height: 19px;
    }
  }

  &.small-size {
    .dropdown-container {
      height: 24px;

      .selected-item {
        border-radius: 4px;
        padding: 0 6px;
      }

      .dropdown-items-list {
        padding: 6px;
      }

      &.active {
        &.element-open-top {
          .dropdown-items-list {
            bottom: 23px;
          }

          &:hover {
            .dropdown-items-list {
              bottom: 24px;
            }
          }

        }
      }
    }

  }

}
