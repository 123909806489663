.template-main-container {
  &.template-id-7 {
    --before-after-color: #4B57DC;

    .page-1 {
      .right-side {
        padding-top: 16px;
      }
    }

    .right-side {
      .work-experience-part {
        padding: 0;

        .work-exp-title-wrap {
          font-size: 22px;
          line-height: 32px;
          width: fit-content;
          margin-bottom: 10px;

          h2 {
            margin-bottom: 0;
          }
        }

        .work-experience-list {
          padding: 0 48px 0 48px;
          //margin-top: 10px;

          .work-experience-item_wrap {
            display: flex;

            .tm-date-container {
              padding-right: 12px;
              min-width: 76px;
              height: fit-content;
              position: relative;

              span {
                display: block;
              }

              .tm-date-wrapper {
                position: absolute;
                z-index: 6;
                left: 0;
                top: 70px;
              }
            }

            .work-experience-item {
              width: 100%;

              .work-exp-item-child {
                &:before {
                  content: "";
                  width: 1px;
                  position: absolute;
                  background-color: var(--before-after-color);
                  left: -8px;
                  top: 2px;
                  bottom: 2px;
                }
              }

              &:before, &:after {
                content: "";
                width: 5px;
                height: 5px;
                background-color: var(--before-after-color);
                position: absolute;
              }

              &:before {
                left: -10px;
                top: 2px;
                border-radius: 50px;
              }

              &:after {
                left: -10px;
                bottom: 2px;
                border-radius: 50px;
              }
            }
          }

        }
      }

      .list-section {
        padding-left: 64px;

        .list-section-title-wrapper {
          font-size: 22px;
          line-height: 32px;
          width: fit-content;
          padding: 2px 16px 2px 62px;
          margin-bottom: 10px;

          h2 {
            margin-bottom: 0;
          }
        }

        .ul-wrapper {
          padding: 0 48px 0 60px;
          //margin-top: 10px;

          .list-sec-ul-parent {
            position: relative;

            &:before, &:after {
              content: "";
              width: 5px;
              height: 5px;
              background-color: var(--before-after-color);
              position: absolute;
            }

            &:before {
              left: -9px;
              top: 2px;
              border-radius: 50px;
            }

            &:after {
              left: -9px;
              bottom: 2px;
              border-radius: 50px;
            }

            ul {
              &:before {
                content: "";
                width: 1px;
                position: absolute;
                background-color: var(--before-after-color);
                left: -8px;
                top: 2px;
                bottom: 2px;
              }
            }
          }
        }
      }
    }

    &.tm-type-sidebar {

      .summary-part {
        margin-right: 40px;
      }

      &.sidebar-position-left {
        .right-side {
          .work-experience-part {
            .work-exp-title-wrap {
              padding: 2px 16px 2px 108px;
            }

            .work-experience-list {
              padding-left: 30px;
              padding-right: 48px;
            }
          }

          .list-section {
            padding: 0 30px 0 48px;

            .list-section-title-wrapper {
              padding: 2px 16px 2px 60px;
            }

            .ul-wrapper {
              padding: 0 0 0 58px;
            }
          }
        }
      }

      &.sidebar-position-right {
        .right-side {
          .work-experience-part {
            padding: 0;

            .work-exp-title-wrap {
              padding: 2px 16px 2px 126px;
            }

            .work-experience-list {
              padding-left: 48px;
              padding-right: 30px;
            }
          }

          .list-section {
            padding: 0 30px 0 48px;

            .list-section-title-wrapper {
              padding: 2px 16px 2px 78px;
            }

            .ul-wrapper {
              padding: 0 0 0 76px;
            }
          }
        }
      }
    }

    &.tm-type-one-row {
      .right-side {
        .work-experience-part {
          .work-exp-title-wrap {
            padding: 2px 16px 2px 126px;
          }
        }
      }
    }

    &.tm-type-two-rows {
      .template-page-parent {
        .template-page {
          .two-row-tm {
            .two-row-body {
              .two-r-left {
                .work-experience-part {
                  padding: 0;

                  .work-exp-title-wrap {
                    padding: 2px 16px 2px 126px;
                  }

                  .work-experience-list {
                    padding-right: 0;
                  }
                }

                .list-section {
                  .ul-wrapper {
                    padding-right: 0;
                  }
                }
              }

              .two-r-right {
                .work-experience-part {
                  padding: 0;

                  .work-exp-title-wrap {
                    padding: 2px 16px 2px 78px;
                  }

                  .work-experience-list {
                    padding-left: 0;
                  }
                }

                .list-section {
                  padding-left: 0;

                  .list-section-title-wrapper {
                    padding: 2px 16px 2px 78px;
                  }

                  .ul-wrapper {
                    padding: 0 48px 0 76px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .sf-line {
      border-top-color: transparent;
    }

    .left-side {
      .sf-line {
        border-top-color: var(--before-after-color);
      }
    }
  }
}
