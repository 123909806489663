.sf-cover-letter-template {
  &.template-id-4 {
    --before-after-color: #E0589E;

    .tm-top-wrapper {
      .name-part {
        h3 {
          padding: 4px 12px 4px 12px;
          border-radius: 0;
        }
      }
    }

    .body-part {
      padding-left: 66px;

      .body-part-first-child {
        position: relative;

        .body-part-second-child {
          &:before {
            content: "";
            width: 1px;
            position: absolute;
            background-color: var(--before-after-color);
            left: -16px;
            top: 8px;
            bottom: 14px;
          }
        }

        &:before, &:after {
          content: '';
          width: 5px;
          height: 5px;
          background-color: var(--before-after-color);
          position: absolute;
        }

        &:before {
          left: -18px;
          top: 6px;
          border-radius: 50px;
        }

        &:after {
          left: -18px;
          bottom: 12px;
          border-radius: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
