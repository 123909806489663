@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?pkra1w');
  src:  url('fonts/icomoon.eot?pkra1w#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?pkra1w') format('truetype'),
    url('fonts/icomoon.woff?pkra1w') format('woff'),
    url('fonts/icomoon.svg?pkra1w#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tm-skype:before {
  content: "\e912";
}
.icon-selfcv:before {
  content: "\e900";
}
.icon-sf-skills:before {
  content: "\e901";
}
.icon-sf-lng:before {
  content: "\e902";
}
.icon-sf-causes:before {
  content: "\e903";
}
.icon-sf-interests:before {
  content: "\e904";
}
.icon-sf-summary:before {
  content: "\e905";
}
.icon-sf-ar-of-exp:before {
  content: "\e906";
}
.icon-sf-work-exp:before {
  content: "\e907";
}
.icon-sf-education:before {
  content: "\e908";
}
.icon-sf-references:before {
  content: "\e909";
}
.icon-sf-prof-org:before {
  content: "\e90a";
}
.icon-sf-publications:before {
  content: "\e90b";
}
.icon-sf-volunteer:before {
  content: "\e90c";
}
.icon-sf-teaching:before {
  content: "\e90d";
}
.icon-sf-achievement:before {
  content: "\e90e";
}
.icon-sf-awards:before {
  content: "\e90f";
}
.icon-sf-conf-and-cours:before {
  content: "\e910";
}
.icon-sf-prof-projects:before {
  content: "\e911";
}
