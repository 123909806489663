.terms-main-container {
  padding: 0 10px;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 48px;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    padding-top: 80px;

    .left-side {
      width: 30%;
      max-width: 720px;
      position: sticky;
      top: 110px;
      height: calc(100vh - 120px);
      overflow: auto;
      min-width: 250px;
      font-size: 14px;

      h2 {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 32px;
      }

      > ul {
        padding: 0;
        list-style-type: none;
        line-height: 24px;

        li {
          line-height: 24px;
          margin-bottom: 16px;
          transition: .3s;
          cursor: pointer;

          > span {
            margin-right: 4px;
          }

          &:hover {
            color: #00A3FF;
          }

          &.active {
            color: #00A3FF;
          }

          &.last-updated {
            letter-spacing: 0.2px;
            color: rgba(0, 0, 0, 0.6);
            cursor: initial;

            &:hover {
              color: rgba(0, 0, 0, 0.6);
            }
          }

        }

      }

    }

    .right-side {
      padding: 0 40px 0 40px;
      width: 70%;
      line-height: 24px;
      border-left: 1px solid rgba(48, 48, 48, 0.3);
      scroll-behavior: smooth;

      .block-item {
        margin-bottom: 40px;

        > h2 {
          font-weight: 700;
          font-size: 22px;
          line-height: 26px;
          color: #303030;
          margin-bottom: 24px;
          transition: .3s;

          > span {
            margin-right: 4px;
          }

          &.active {
            color: #00A3FF;
          }
        }

        > p {

        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .content {
      .left-side {
        margin-right: 10px;
      }

      .right-side {
        padding: 0 10px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      .left-side {
        display: none;
      }

      .right-side {
        padding: 0;
        width: 100%;
        border-left: initial;

        .block-item {

          > h2 {
            &.active {
              color: initial;
            }
          }
        }
      }


    }

  }

}
