@use "../global/variables" as variables;
@use "../global/placeholders" as *;

.confirm-modal-main-container {
  width: 450px;
  height: 274px;
  padding: 40px;
  background-color: variables.$white;
  box-shadow: variables.$shadowStronger;
  border-radius: variables.$borderRadiusL;
  font-family: variables.$sfFontFamily;
  color: #383B3D;
  line-height: 22px;
  font-weight: variables.$boldFont;
  position: relative;
  @extend %flexColumnBetween;

  .confirm-modal-title {
    margin-bottom: 0;
  }

  .confirm-modal-description {
    font-size: 16px;
  }

  .modal-close {
    position: absolute;
    right: 20px;
    font-size: 24px;
    top: 20px;
    cursor: pointer;
  }

  .buttons-area {
    @extend %flexBetween;

    button {
      width: 173px;
    }

    button:first-child {
      margin-right: 15px;
    }

  }

}

@media only screen and (max-width: variables.$mediumScreen) {
  .confirm-modal-main-container {
    width: 350px;
    padding: 40px 20px;
  }
}


